import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;

	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1280.000000 932.000000">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,932.000000) scale(0.100000,-0.100000)">
					<path d="M6840 4412 c-91 -152 -199 -333 -241 -402 -42 -69 -98 -163 -126
-210 -54 -92 -217 -361 -241 -397 l-14 -23 1191 0 1191 0 0 655 0 655 -797 0
-798 -1 -165 -277z"/>
					<path d="M5050 2863 c-34 -13 -50 -37 -50 -74 0 -33 5 -41 31 -55 81 -41 166
39 112 105 -19 22 -67 34 -93 24z"/>
					<path d="M5580 2496 l0 -314 65 -4 65 -3 0 135 c0 109 3 142 18 170 29 59 106
67 130 15 8 -16 12 -81 12 -170 l0 -145 71 0 71 0 -4 178 c-3 156 -5 181 -23
212 -41 71 -143 91 -224 45 -23 -14 -45 -25 -47 -25 -2 0 -4 50 -4 110 l0 110
-65 0 -65 0 0 -314z"/>
					<path d="M3442 2713 l3 -68 123 -5 124 -5 -136 -181 c-129 -172 -136 -184
-136 -228 l0 -46 245 0 245 0 0 60 0 60 -145 0 c-80 0 -145 3 -145 8 1 4 64
90 140 192 136 180 140 186 138 230 l-3 45 -228 3 -228 2 3 -67z"/>
					<path d="M5297 2744 c-4 -4 -7 -31 -7 -61 l0 -53 -40 0 -40 0 0 -55 0 -55 40
0 40 0 0 -170 0 -170 70 0 70 0 0 170 0 170 45 0 45 0 0 55 0 55 -45 0 -44 0
-3 58 -3 57 -60 3 c-34 2 -64 0 -68 -4z"/>
					<path d="M4100 2624 c-143 -62 -185 -255 -81 -375 48 -57 99 -79 187 -79 57 0
80 5 124 28 30 15 55 32 57 37 1 6 -12 27 -30 48 -32 37 -33 38 -62 23 -60
-31 -160 -13 -185 33 -5 11 -10 22 -10 25 0 3 69 7 153 8 l152 3 3 38 c7 86
-49 178 -130 211 -47 20 -132 20 -178 0z m162 -126 c33 -43 25 -48 -72 -48
-93 0 -104 6 -77 41 35 45 116 49 149 7z"/>
					<path d="M4673 2624 c-17 -7 -34 -18 -38 -24 -10 -17 -25 -11 -25 10 0 18 -7
20 -65 20 l-65 0 0 -225 0 -225 65 0 65 0 0 138 c0 119 3 141 20 169 32 53
106 58 128 8 7 -16 12 -81 12 -169 0 -78 2 -145 4 -147 2 -2 34 -2 71 -1 l67
4 -4 176 c-3 156 -5 181 -23 212 -37 64 -134 88 -212 54z"/>
					<path d="M5010 2406 l0 -224 66 -4 c36 -1 68 -1 70 1 2 2 4 105 4 228 l0 223
-70 0 -70 0 0 -224z"/>
					<path d="M11838 76 c3 -5 -3 -6 -13 -2 -10 3 -28 0 -41 -9 -13 -8 -31 -12 -40
-8 -12 4 -15 3 -10 -5 4 -8 2 -10 -7 -6 -8 3 -17 -2 -20 -10 -4 -9 -14 -16
-24 -16 -10 0 -25 -5 -33 -10 -10 -7 77 -10 263 -9 171 0 275 4 272 10 -4 5
-15 9 -25 9 -10 0 -20 4 -22 10 -5 17 -103 47 -159 49 -30 1 -75 3 -101 4 -26
1 -44 -2 -40 -7z m18 -24 c-16 -2 -32 0 -37 5 -5 5 7 7 29 4 34 -5 35 -6 8 -9z
m141 2 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m40 0 c-3 -3
-12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z"/>
					<path d="M5157 54 c-4 -4 -7 -18 -7 -31 0 -20 5 -23 36 -23 33 0 35 2 32 27
-2 21 -9 29 -28 31 -14 2 -29 0 -33 -4z"/>
					<path d="M5430 15 c0 -10 10 -15 30 -15 20 0 30 5 30 15 0 10 -10 15 -30 15
-20 0 -30 -5 -30 -15z"/>
					<path d="M4395 10 c-4 -6 33 -10 105 -10 72 0 109 4 105 10 -8 13 -202 13
-210 0z"/>
				</g>
			</svg>
		</Box>
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
